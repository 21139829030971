module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-3789fd47ba/4/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"surva network plugins","short_name":"surva plugins","start_url":"/","background_color":"#ffffff","theme_color":"#577a2e","display":"minimal-ui","icon":"src/images/surva-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"36175468208f00ed5a68b857b6284979"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-b389b746f9/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
